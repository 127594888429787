import {
  CaretDownOutlined,
  CaretRightOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Image,
  Input,
  message,
  Progress,
  Row,
  Select,
  Skeleton,
  Switch,
  Tag,
  theme,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import Table2 from "../../../components/tables/table2";
import { AddCommas, ConvertParams, FormatDateRange } from "../../../config";
import { DymmyImage } from "../../../config/fakeData";
import { CardViewWrapper, Wrapper } from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Text, Title, Paragraph } = Typography;
const { Panel } = Collapse;

const CardView = ({
  title,
  setSelectedCard,
  list,
  selectedCard,
  salesList,
  unitsList,
  costsList,
  AdvertisingCostList,
  shipping_cost_list,

  summaryLoading,

  refundList,
  AmazonFeesList,
  getTable,
  firstCard,
}) => {
  const { token } = theme.useToken();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const {
    sales,
    total_unit,
    filter_name,
    organic_unit,
    profit_per_unit,
    roi,
    ppc_per_unit,
    acos,
    sessions,
    conversion_rate,
    end_date,
    total_costs,
    net_profit,
    refund,
    filter_key,
    sd_unit,
    total_profit,
    sp_unit,
    refund_unit,
    other,
    profit_pect,
    cost_pect,
    gross_profit,
    expenses,
    start_date,
    vat,
    more_data,
    refund_cost,
    refunds,
  } = list?.[title?.indexKey] || {};

  // const { sales } = more_data;
  const toggleExpand = () => {
    setExpanded(!expanded);
  };
  const panelStyle = {
    background: "transparent",
    borderRadius: token.borderRadiusLG,
    border: "none",
    width: "100%",
  };

  const loading = summaryLoading;
  return (
    <CardViewWrapper span={24}>
      <Card
        title={
          <div
            style={{
              background: title?.color,
              paddingInline: "20px",
              paddingTop: "10px",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {loading ? (
                <>
                  {" "}
                  <Skeleton.Input className="mb-1" size="large" active />
                </>
              ) : (
                <div>
                  <Text style={{ color: "#ffffff", fontSize: "20px" }}>
                    {filter_name || "-"}
                  </Text>{" "}
                  <br />
                  <span
                    style={{
                      color: "#ffffff",
                      fontWeight: 400,
                      fontSize: "13px",
                    }}
                  >
                    {FormatDateRange(start_date, end_date)}
                  </span>
                </div>
              )}

              {filter_key === "today" ? (
                <> </>
              ) : loading ? (
                <Skeleton.Button className="mb-1" active />
              ) : (
                <Text
                  className="values-text"
                  style={{ color: "#28a745", fontSize: "16px" }}
                >
                  15.8% ↑{" "}
                  <p style={{ color: "#fff", fontSize: "12px" }}>
                    {t("Last year")}
                  </p>
                </Text>
              )}
            </div>
          </div>
        }
        hoverable
        headStyle={{
          borderRadius: "12px",
          boxShadow: `${
            selectedCard?.title === title?.title
              ? `${title?.color}7a 0px 7px 29px 0px`
              : ""
          }`,
          borderBottom: "none",
          border: `${
            selectedCard?.title === title?.title
              ? `3px solid ${title?.color}`
              : "3px solid #fff"
          } `,
        }}
        style={{
          borderRadius: "8px",
        }}
        onClick={(e) => {
          getTable({
            start_date: start_date,
            end_date: end_date,
          });
          setSelectedCard({
            color: title?.color,
            title: filter_name,
            date: FormatDateRange(start_date, end_date),
          });
        }}
        bodyStyle={{
          marginTop: "10px",
          borderRadius: "12px",
          border: `${
            selectedCard?.title === title?.title
              ? "3px solid #32a4ec"
              : "3px solid #fff"
          } `,
          boxShadow: `${
            selectedCard?.title === title?.title
              ? "#32a4ec57 0px 7px 29px 0px"
              : ""
          }`,
        }}
      >
        {loading ? (
          Array(4)
            .fill(0)
            .map((_, index) => (
              <Row key={index}>
                <Col flex="auto">
                  <Skeleton.Input className="mb-1" size="large" active />
                </Col>
                <Col flex="none">
                  <Skeleton.Button className="mb-1" size="large" active />
                </Col>
              </Row>
            ))
        ) : (
          <>
            {/* Revenue */}
            <Row justify="space-between">
              <Col>
                <Text strong>{t("Revenue")}</Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#004085", fontSize: "20px" }}>
                  {AddCommas(sales || 0)} €
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Sales */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Sales")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(more_data?.sales?.total_sales || 0)}€
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {salesList.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text>
                            {AddCommas(more_data?.sales?.[item.value] || 0)}€
                          </Text>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* units */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Units")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(more_data?.units?.total_units || 0)}
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {unitsList.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text>
                            {AddCommas(more_data?.units?.[item.value] || 0)}
                          </Text>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Promo */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Promo")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(more_data?.promo || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Refund */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Refund")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(refunds || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* Advertising cost */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Advertising cost")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(
                          more_data?.advertising_cost?.total_ad_cost || 0
                        )}
                        €
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {AdvertisingCostList.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text>
                            {AddCommas(
                              more_data?.advertising_cost?.[item.value] || 0
                            )}
                            €
                          </Text>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* Giftwrap */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Giftwrap")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(more_data?.giftwrap || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* Shipping costs */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Shipping costs")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(
                          more_data?.shipping_cost?.total_shipping_chargeback ||
                            0
                        )}
                        €
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />
                {shipping_cost_list.map((item, index) => (
                  <div key={index}>
                    <Row justify="space-between">
                      <Col style={{ maxWidth: "100px" }}>
                        <Text>{item.name}</Text>
                      </Col>
                      <Col
                        span={16}
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <div
                          style={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text>
                            {AddCommas(
                              more_data?.shipping_cost?.[item.value] || 0
                            )}
                            €
                          </Text>
                        </div>
                      </Col>
                    </Row>
                    <Divider className="my-2" />
                  </div>
                ))}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* Refund cost */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Refund cost")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(more_data?.refund_cost?.refund_total || 0)}€
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />

                {refundList.map((item, index) => {
                  const refundValue = more_data?.refund_cost?.[item.value] || 0;
                  if (refundValue === 0) return;
                  return (
                    <div key={index}>
                      <Row justify="space-between">
                        <Col style={{ width: "min-content" }} flex="auto">
                          <Text>{item.name}</Text>
                        </Col>
                        <Col
                          span={5}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <div
                            style={{
                              width: "70%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Text
                              style={{
                                color: item.color || "inherit",
                                display: "flex",
                              }}
                            >
                              {AddCommas(refundValue)}€
                            </Text>
                          </div>
                        </Col>
                      </Row>
                      <Divider className="my-2" />
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* AmazonFeesList */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Amazon fees")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(
                          more_data?.amazon_fees?.amazon_fees_total || 0
                        )}
                        €
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />

                {AmazonFeesList.map((item, index) => {
                  const AmazonFeesValues =
                    more_data?.amazon_fees?.[item.value] || 0;
                  if (AmazonFeesValues === 0) return;
                  return (
                    <div key={index}>
                      <Row justify="space-between">
                        <Col style={{ width: "min-content" }} flex="auto">
                          <Text>{item.name}</Text>
                        </Col>
                        <Col
                          span={5}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <div
                            style={{
                              width: "70%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Text
                              style={{
                                color: item.color || "inherit",
                                display: "flex",
                              }}
                            >
                              {AddCommas(AmazonFeesValues)}€
                            </Text>
                          </div>
                        </Col>
                      </Row>
                      <Divider className="my-2" />
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* Cost of goods */}
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              style={{
                background: token.colorBgContainer,
              }}
            >
              <Panel
                style={panelStyle}
                header={
                  <Row justify="space-between">
                    <Col>
                      <Text strong>{t("Cost of goods")}</Text>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "start" }}
                    >
                      <Text strong>
                        {AddCommas(more_data?.cost_of_goods?.total_cogs || 0)}€
                      </Text>
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Divider className="my-2" />

                {costsList.map((item, index) => {
                  const costValues =
                    more_data?.cost_of_goods?.[item.value] || 0;
                  if (costValues === 0) return;
                  return (
                    <div key={index}>
                      <Row justify="space-between">
                        <Col style={{ width: "min-content" }} flex="auto">
                          <Text>{item.name}</Text>
                        </Col>
                        <Col
                          span={5}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <div
                            style={{
                              width: "70%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                            }}
                          >
                            <Text
                              style={{
                                color: item.color || "inherit",
                                display: "flex",
                              }}
                            >
                              {AddCommas(costValues)}€
                            </Text>
                          </div>
                        </Col>
                      </Row>
                      <Divider className="my-2" />
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            {/* VAT */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("VAT")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(more_data?.vat || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Gross profit */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Gross profit")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(more_data?.gross_profit || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Expenses */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Expenses")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(expenses || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />
            {/* Net profit */}
            <Row justify="space-between">
              <Col span={12}>
                <Text>{t("Net profit")}</Text>
              </Col>
              <Col span={12}>
                <Text
                  strong
                  style={{
                    color: "#004085",

                    marginLeft: "17px",
                  }}
                >
                  {AddCommas(more_data?.net_profit || 0)}€
                </Text>
              </Col>
            </Row>
            <Divider
              style={{
                borderColor: "#ccc",
                borderWidth: "2px",
              }}
              className="my-2"
            />

            <Row justify="space-between" align="middle">
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  {t("Profit")}
                </Text>
              </Col>
              <Col>
                <Text strong style={{ color: "#28a745" }}>
                  {AddCommas(total_profit || 0)} €
                </Text>
              </Col>
              <Col>
                <Text style={{ color: "#28a745" }}>
                  {AddCommas(profit_pect)}%
                </Text>
              </Col>
              <Col>
                <Text
                  onClick={toggleExpand}
                  style={{ cursor: "pointer", color: "#28a745" }}
                >
                  {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Text>
              </Col>
            </Row>

            {expanded && (
              <div
                style={{
                  marginTop: 10,
                  backgroundColor: "#d5fbddcc",
                  padding: 10,
                }}
              >
                <Row justify="space-between">
                  <Col span={8}>
                    <Text>{t("Profit per unit")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(profit_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>{t("ROI")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(roi || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>{t("PPC per unit")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(ppc_per_unit || 0)} €</Text>
                  </Col>
                  <Col span={8}>
                    <Text>{t("ACOS")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(acos || 0)}%</Text>
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col span={8}>
                    <Text>{t("Sessions")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(sessions || 0)}</Text>
                  </Col>
                  <Col span={8}>
                    <Text>{t("Conversion")}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{AddCommas(conversion_rate || 0)}%</Text>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </Card>
    </CardViewWrapper>
  );
};
export default function Dashboard(props) {
  const { userData } = props;
  const { t } = useTranslation();
  const [filterLoading, setFilterLoading] = useState(true);
  const [filterData, setFilterData] = useState({});
  const [summaryList, setSummaryList] = useState([]);
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState({
    filterCard: null,
    sku_lists: [],
    sales_channel: "Amazon.de",
    start_date: null,
    end_date: null,
  });
  const navigate = useNavigate();
  const [goalData, setGoalData] = useState({});
  const [goalDataLoading, setGoalDataLoading] = useState(true);
  const [productList, setProductList] = useState([]);
  const [productLoading, setProductLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState({
    title: null,
    color: null,
    date: null,
  });
  const [firstCard, setFirstCard] = useState({});
  const [productListFilter, setProductListFilter] = useState([]);
  const [productListFilterLoading, setProductListFilterLoading] =
    useState(true);

  const [salesChannelList, setSalesChannelList] = useState([]);

  const DashboardProductsAction = async (data) => {
    setProductLoading(true);
    const response = await MakeApiCall(
      `dashboard/products${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setProductList(response?.data || []);
      setProductLoading(false);
    } else {
      setProductList([]);
      message.warning(response?.message);
      setProductLoading(false);
    }
  };
  const cardDetails = {
    datefilter1: [
      {
        cardTitle: [
          {
            color: "#601A3E",

            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter2: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            indexKey: 4,
            color: "#C9A2C9",
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#4B0076",
            indexKey: 2,
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter3: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            color: "#311465",
            indexKey: 1,
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    datefilter4: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
          {
            color: "#AF69ED",
            indexKey: 3,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 1,
            color: "#311465",
          },
          {
            color: "#C9A2C9",
            indexKey: 4,
          },
        ],
      },
      {
        cardTitle: [
          {
            indexKey: 2,
            color: "#4B0076",
          },
          {
            color: "#957A81",
            indexKey: 5,
          },
        ],
      },
    ],
    custom: [
      {
        cardTitle: [
          {
            color: "#601A3E",
            indexKey: 0,
          },
        ],
      },
    ],
  };
  const GetSalesControllingAction = async () => {
    setFirstCard([]);
    const response = await MakeApiCall(
      `sales-controlling`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setFirstCard(
        response?.data?.records
          ?.filter((d, i) => i !== 0)
          ?.reduce((acc, item) => {
            acc[item?.salesControllerParam?.slug] = item.param_value;
            return acc;
          }, {})
      );
    } else {
      setFirstCard([]);
      message.warning(response?.message);
    }
  };

  const GetProductsListAction = async (data) => {
    setProductListFilter([]);
    setProductListFilterLoading(true);
    const response = await MakeApiCall(
      `products?is_all=1`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setProductListFilterLoading(false);
      setProductListFilter(response?.data?.records || []);
    } else {
      setProductListFilterLoading(false);
      setProductListFilter([]);
      message.warning(response?.message);
    }
  };

  const SalesChannelsAction = async () => {
    setSalesChannelList([]);
    const response = await MakeApiCall(
      `sales-channels`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setSalesChannelList(response?.data || []);
    } else {
      setSalesChannelList([]);
      message.warning(response?.message);
    }
  };

  const DashboardSummaryAction = async (data) => {
    setSummaryLoading(true);
    const response = await MakeApiCall(
      `dashboard/summary${ConvertParams({
        ...data,
        date_filter: data?.filterCard || data?.date_filter,
      })}`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        setProductLoading(true);
        DashboardProductsAction({
          start_date: response?.data?.[0]?.start_date,
          end_date: response?.data?.[0]?.end_date,
          sku_lists: data?.sku_lists,
          sales_channel: data?.sales_channel,
        });
        setSelectedFilters((prev) => {
          setSelectedCard({
            title: response?.data?.[0]?.filter_name,
            color: cardDetails?.[prev?.filterCard]?.[0]?.cardTitle?.[0]?.color,
            date: FormatDateRange(
              response?.data?.[0]?.start_date,
              response?.data?.[0]?.end_date
            ),
            sales_channel: data?.sales_channel,
          });

          return prev;
        });
      }
      setSummaryList(response?.data || []);
      setSummaryLoading(false);
    } else {
      message.warning(response?.message);
      setSummaryLoading(false);
    }
  };
  const GoalMonthlyProfitAction = async () => {
    setGoalDataLoading(true);
    setGoalData({});
    const response = await MakeApiCall(
      `goal/monthly-profit-goal`,
      "get",
      null,
      true,
      {},
      userData?.token
    );
    if (response?.status) {
      setGoalDataLoading(false);
      setGoalData(response?.data?.profit_data || {});
    } else {
      setGoalDataLoading(false);
      setGoalData({});
      message.warning(response?.message);
    }
  };

  const getDashboardFilterAction = async () => {
    setFilterLoading(true);
    const response = await MakeApiCall(
      `dashboard/get-filter`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      const obj =
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: filterTitle?.[key],
          value: key,
        })) || {};
      setFilterData(obj);
      if (obj?.length > 0) {
        setSelectedFilters({ ...selectedFilters, filterCard: obj?.[0]?.value });
        setSummaryLoading(true);
        DashboardSummaryAction({
          date_filter: obj?.[0]?.value,
          sku_lists: selectedFilters?.sku_lists?.join(","),
          sales_channel: selectedFilters?.sales_channel,
        });
      } else {
        setSummaryLoading(false);
      }

      setFilterLoading(false);
    } else {
      message.warning(response?.message);
      setFilterLoading(false);
    }
  };

  useEffect(() => {
    GetProductsListAction();
    getDashboardFilterAction();
    GoalMonthlyProfitAction();
    GetSalesControllingAction();
    SalesChannelsAction();
    return () => {};
  }, []);

  const salesList = [
    {
      name: t("Organic"),
      value: "organic",
    },
    {
      name: t("Sponsored Products (same day)"),
      value: "sp_sales",
    },
    {
      name: t("Sponsored Display (same day)"),
      value: "sd_sales",
    },
  ];
  const costsList = [
    {
      name: t("Cost of goods sold"),
      value: "cost_of_goods_sold",
    },
    {
      name: t("Lost/damaged by Amazon"),
      value: "cost_of_goods_sold",
    },
  ];
  const unitsList = [
    {
      name: t("Organic"),
      value: "organic",
    },
    {
      name: t("Sponsored Products (same day)"),
      value: "sp_units",
    },
    {
      name: t("Sponsored Display (same day)"),
      value: "sd_units",
    },
  ];
  const AdvertisingCostList = [
    {
      name: t("Sponsored Products"),
      value: "sp_cost",
    },
    {
      name: t("Sponsored Brands Video"),
      value: "sbv_cost",
    },
    {
      name: t("Sponsored Display"),
      value: "sd_cost",
    },
    {
      name: t("Sponsored Brands"),
      value: "sb_cost",
    },
  ];
  const AmazonFeesList = [
    {
      name: t("FBA per unit fulfilment fee"),
      value: "fba_per_unit_fulfilment_fee",
    },
    {
      name: t("Referral fee"),
      value: "referral_fee",
    },
    {
      name: t("Coupon redemption fee"),
      value: "coupon_redemption_fee",
    },
    {
      name: t("FBA storage fee"),
      value: "fba_storage_fee",
    },
    {
      name: t("Inbound transportation"),
      value: "inbound_transportation",
    },
    {
      name: t("Subscription"),
      value: "subscription",
    },
    {
      name: t("FBA removal fee"),
      value: "fba_removal",
    },
    {
      name: t("Compensated clawback"),
      value: "compensated_clawback",
    },
    {
      name: t("Variable closing fee"),
      value: "variable_closing_fee",
    },
    {
      name: t("Long term storage fee"),
      value: "long_term_storage_fee",
    },
    {
      name: t("Warehouse damage"),
      value: "warehouse_damage",
    },
    {
      name: t("Reversal reimbursement"),
      value: "reversal_reimbursement",
    },
  ];
  const shipping_cost_list = [
    {
      name: t("FBA shipping chargeback"),
      value: "fba_shipping_chargeback",
    },
  ];
  const refundList = [
    {
      name: t("Refunded amount"),
      value: "refunded_amount",
    },
    {
      name: t("Refunded commission"),
      value: "refund_commission",
    },
    {
      name: t("Refunded shipping"),
      value: "refunded_shipping",
    },
    {
      name: t("FBA shipping chargeback"),
      value: "fba_shipping_chargeback",
    },
    {
      name: t("Ship Promation"),
      value: "ship_promotion",
    },
    {
      name: t("Promotion"),
      value: "promotion",
    },

    {
      name: t("Value of returned items"),
      value: "refunded_referral_fee",
    },
    {
      name: t("Refunded referral fee"),
      value: "refunded_referral_fee",
    },
  ];
  const columns = [
    {
      title: t("Product"),

      width: 400,
      render: (text, row) => (
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f0f5ff",
            padding: "10px",
            borderRadius: "12px",
          }}
        >
          <Image
            style={{ borderRadius: "18px" }}
            src={row?.image_url || DymmyImage}
            width={75}
            height={75}
            onError={(e) => {
              e.target.src = DymmyImage;
            }}
          />
          <div className="ms-3">
            <div className="d-flex">
              <Tag color="#AF69ED" bordered={false}>
                {row?.asin || "-"}
              </Tag>
              <Tag color="#C8A2C9" bordered={false}>
                {row?.sku || "-"}
              </Tag>
            </div>
            <div
              className="my-2"
              style={{
                width: "350px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontWeight: 600,
              }}
            >
              {row?.product_name || "-"}
            </div>
            <div>
              <Tag color="#957A81" bordered={false}>
                {row?.price}€
                {row?.product_type ? ` - ${row?.product_type}` : ""}
              </Tag>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: t("Units Sold"),
      dataIndex: "unit_sold",
      key: "unit_sold",
    },
    {
      title: t("Refunds"),
      dataIndex: "refund",
      key: "refund",
      render: (text) => `${text?.toLocaleString()}€`, // Format refunds as currency
    },
    {
      title: t("Sales"),
      dataIndex: "sales",
      key: "sales",
      render: (text) => `${text?.toLocaleString()}€`, // Format sales as currency
    },
    {
      title: t("Ads"),
      dataIndex: "ads",
      key: "ads",
      render: (text) => `${text?.toLocaleString()}€`, // Format ads as currency
    },
    {
      title: t("Net Profit"),
      dataIndex: "net_profit",
      key: "net_profit",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: t("Margin"),
      dataIndex: "margin",
      key: "margin",
    },
    {
      title: t("ROI"),
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: t("Storage"),
      dataIndex: "storage",
      key: "storage",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: t("PPC"),
      dataIndex: "ppc",
      key: "ppc",
      render: (text) => `${text?.toLocaleString()}€`, // Format net profit as currency
    },
    {
      title: t("BSR"),
      dataIndex: "bsr",
      key: "bsr",
    },
    {
      title: t("Info"),
      dataIndex: "info",
      key: "info",
      render: (text) => <Tag>{text || "-"}</Tag>,
    },
  ];

  const filterTitle = {
    datefilter1: t(
      "Today / Yesterday / 2 days ago / Last 7 days / Last 14 days / Last 30 days"
    ),
    datefilter2: t(
      "Today / Yesterday / 2 days ago / This month / Last month / 2 month ago"
    ),
    datefilter3: t(
      "This month / Last month / 2 month ago / 3 month ago / 4 month ago / 5 month ago"
    ),
    datefilter4: t(
      "This month / Last month / 2 month ago / This year / Last year / 2 years ago"
    ),
  };
  const filterOption = (input, option) => {
    return (
      option?.asin1?.toLowerCase().includes(input?.toLowerCase()) ||
      option?.seller_sku?.toLowerCase().includes(input?.toLowerCase()) ||
      option?.item_name?.toLowerCase().includes(input?.toLowerCase())
    );
  };
  return (
    <Wrapper className="fadeInUp">
      <div className="mb-3 prosess-card">
        <Row className="d-flex justify-content-between" gutter={[16, 16]}>
          <Col
            className="d-flex justify-content-start align-items-center"
            flex={"none"}
          >
            <Typography>
              <Title level={4}>
                {t("Monthly profit goal:")}{" "}
                <Icon
                  style={{ position: "relative", top: "3px" }}
                  icon="material-symbols:info"
                />
              </Title>

              <Paragraph className="mb-0">
                <small>*</small>
                <span style={{ fontSize: "0.8rem" }}>
                  {t("Average Profit Over the Last 12 Month")}
                </span>
              </Paragraph>
            </Typography>
          </Col>
          <Col className="d-flex align-items-center " flex={"auto"}>
            <div className="d-grid px-10" style={{ width: "100%" }}>
              <Progress
                percent={parseInt(goalData?.profit_pect || 0)}
                percentPosition={{
                  align: "end",
                  type: "inner",
                }}
                size={["100%", 20]}
                strokeColor="#2e9520"
              />
              <div className="d-flex justify-content-center align-items-center mt-2">
                <span>
                  {AddCommas(parseInt(goalData?.monthly_profit || 0))}€
                </span>
                &nbsp;<b>/</b>&nbsp;
                <b>
                  {AddCommas(parseInt(goalData?.monthly_profit_goal || 0))}€
                </b>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-end align-items-center"
            // span={4}
            flex={"none"}
          >
            <Button
              onClick={() => {
                navigate("/goal");
              }}
              style={{ borderRadius: "7px" }}
              type="primary"
            >
              {t("Analyse")}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="card mb-3" style={{ borderRadius: "12px" }}>
        <div className="card-header d-flex align-items-center justify-content-between">
          <div className="card-title">
            <Select
              placeholder={t("Search by name, tag, SKU, ASIN")}
              showSearch
              loading={productListFilterLoading}
              filterOption={filterOption}
              dropdownStyle={{ width: "40%" }}
              onChange={(e) => {
                setSelectedFilters({
                  ...selectedFilters,
                  sku_lists: e?.map((d) => d?.value),
                });
                DashboardSummaryAction({
                  ...selectedFilters,
                  sku_lists: e?.map((d) => d?.value)?.join(","),
                });
              }}
              value={selectedFilters?.seller_sku}
              labelInValue
              optionLabelProp="label"
              style={{ width: "350px", minWidth: "350px" }}
              maxTagCount="responsive"
              mode="multiple"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip
                  overlayStyle={{
                    pointerEvents: "none",
                  }}
                  title={omittedValues.map(({ label }) => label).join(", ")}
                >
                  <span>{t("Hover Me")}</span>
                </Tooltip>
              )}
            >
              {productListFilter.map((product, i) => (
                <Select.Option
                  key={i}
                  value={product.seller_sku}
                  label={product.item_name}
                  // sku={product?.seller_sku}
                  {...product}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "40px", height: "40px" }}>
                      <Avatar
                        src={product.image_url || DymmyImage}
                        shape="square"
                        size={40}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <div
                        style={{
                          maxWidth: "100%",
                          whiteSpace: "normal",
                          wordBreak: "break-all",
                          overflowWrap: "breakWord",
                          hyphens: "auto",
                          // overflow: "hidden",
                          // textOverflow: "ellipsis",
                          // width: "126px",
                        }}
                      >
                        {product.item_name || "-"}
                      </div>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="card-toolbar d-flex align-items-center justify-content-end">
            <div
              className="ms-3"
              style={{ border: "1px solid #bfbfbf", borderRadius: "30px" }}
            >
              <Select
                options={filterData}
                loading={filterLoading}
                value={selectedFilters?.filterCard}
                onChange={(e) => {
                  if (e === "custom") {
                    const obj = {
                      ...selectedFilters,
                      filterCard: e,
                      start_date: null,
                      end_date: null,
                    };
                    setSelectedFilters(obj);
                    message.destroy();
                    message.warning(
                      "Please select date range after choosing custom filter"
                    );
                    // DashboardSummaryAction({
                    //   ...obj,
                    //   sku_lists: selectedFilters?.sku_lists?.join(","),
                    // });
                  } else {
                    const obj = {
                      ...selectedFilters,
                      filterCard: e,
                      start_date: null,
                      end_date: null,
                    };
                    setSelectedFilters(obj);
                    DashboardSummaryAction({
                      ...obj,
                      sku_lists: selectedFilters?.sku_lists?.join(","),
                    });
                  }
                }}
                className="ms-3"
                variant="borderless"
              />
            </div>

            {selectedFilters?.filterCard === "custom" && (
              <div>
                <DatePicker.RangePicker
                  onChange={(e) => {
                    const obj = {
                      ...selectedFilters,
                      start_date: e?.[0]?.format("YYYY-MM-DD"),
                      end_date: e?.[1]?.format("YYYY-MM-DD"),
                    };
                    setSelectedFilters(obj);
                    DashboardSummaryAction({
                      ...obj,
                    });
                  }}
                  className="ms-3"
                />
              </div>
            )}

            <Select
              className="ms-3"
              options={salesChannelList?.map((d) => ({ label: d, value: d }))}
              placeholder={t("Select Marketplace")}
              value={selectedFilters?.sales_channel}
              onChange={(e) => {
                DashboardSummaryAction({
                  ...selectedFilters,
                  sales_channel: e,
                  sku_lists: selectedFilters?.sku_lists?.join(","),
                });
                setSelectedFilters({
                  ...selectedFilters,
                  sales_channel: e,
                });
              }}
            />
          </div>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        {cardDetails?.[selectedFilters?.filterCard]?.map((d, i) => (
          <Col span={8} key={i}>
            <Row gutter={[16, 16]}>
              {d?.cardTitle?.map((d) => (
                <CardView
                  firstCard={firstCard}
                  salesList={salesList}
                  unitsList={unitsList}
                  AdvertisingCostList={AdvertisingCostList}
                  shipping_cost_list={shipping_cost_list}
                  summaryLoading={summaryLoading}
                  setSelectedCard={setSelectedCard}
                  title={d}
                  refundList={refundList}
                  AmazonFeesList={AmazonFeesList}
                  list={summaryList}
                  getTable={DashboardProductsAction}
                  selectedCard={selectedCard}
                  costsList={costsList}
                />
              ))}
            </Row>
          </Col>
        ))}
      </Row>
      <div className="card p-5 pt-2 mt-5">
        <div className="card-header d-flex align-items-center mb-2">
          <div className="card-title">
            {selectedCard?.title || "-"} &nbsp;
            <Tag color={selectedCard?.color}>{selectedCard?.date || "-"}</Tag>
          </div>
          <div className="card-toolbar">
            <Text
              className="values-text"
              style={{ color: "#28a745", fontSize: "16px" }}
            >
              15.8% ↑{" "}
              <p style={{ color: "#000", fontSize: "12px" }}>
                {t("Last year")}
              </p>
            </Text>
          </div>
        </div>
        <Table2
          dataSource={productList}
          columns={columns}
          scroll={{ x: "max-content" }}
          loading={productLoading}
          pagination={false} // You can enable pagination if needed
        />
      </div>
    </Wrapper>
  );
}
