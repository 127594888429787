import { Icon } from "@iconify/react/dist/iconify.js";
import { Breadcrumb, Layout, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DefaultAdminPage,
  DefaultUserPage,
  FormatUserName,
} from "../../../config";
import { fakeImage } from "../../../config/fakeData";
import { BackToAdminWrapper } from "./style";

const { Header } = Layout;
const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function HeaderView({ user, setData, location }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { email, name } = user?.user?.user_data;

  return (
    <Header
      className="slideInDown"
      style={{
        padding: 0,
        background: "rgb(255 255 255 / 50%)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "15px",
        margin: "0px 15px",
        borderRadius: "0px 0px 18px 18px",
        height: "84px",
        boxShadow:
          "inset 0 0 1px 1px hsla(0,0%,100%,.9),0 20px 27px 0 rgba(0,0,0,.05)",
        position: "sticky",
        top: 0,
        zIndex: 9,
        transition: "box-shadow .25s ease-in,background-color .25s ease-in",
        backdropFilter: "saturate(70%) blur(13px)",
        borderBottom: "1px solid #534cab59",
        borderRight: "1px solid #534cab59",
        borderLeft: "1px solid #534cab59",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="d-flex ms-4">
          <Breadcrumb
            separator={"/"}
            items={[
              {
                title: <Icon icon="tabler:home-filled" width={20} />,
              },
              ...location?.pathname
                ?.split("/")
                ?.filter((d) => d)
                ?.map((d) => ({
                  title: (
                    <span
                      style={{
                        fontWeight: 500,
                        color: "#3d434ae6",
                        fontSize: "15px",
                      }}
                    >
                      {FormatUserName(d)}
                    </span>
                  ),
                })),
            ]}
          />
        </div>
      </div>

      <div className="d-flex align-items-center cursor-pointer me-3">
        {console.log(i18n.language, "i18n.language")}
        <Select
          placeholder="Select Language"
          onChange={(e) => {
            setData({
              ...user,
              i18n: e,
            });
            i18n.changeLanguage(e);
          }}
          value={i18n.language === "en-US" ? "en" : i18n.language}
          className="w-150px me-3"
          options={[
            {
              label: "English",
              value: "en",
            },
            {
              label: "German",
              value: "de",
            },
          ]}
        />
        {user?.admin && (
          <div
            className="d-flex align-items-center align-items-stretch mx-1 me-3"
            style={{
              height: "70px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BackToAdminWrapper
              onClick={() => {
                setData(user?.admin);
                navigate(findRoutes?.[user?.admin?.loginType]);
              }}
            >
              <button className="Btn-Container">
                <span className="text">{t("Back to Admin")}</span>
                <span className="icon-Container">
                  <svg
                    width={16}
                    height={19}
                    viewBox="0 0 16 19"
                    fill="nones"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="1.61321" cy="1.61321" r="1.5" fill="black" />
                    <circle cx="5.73583" cy="1.61321" r="1.5" fill="black" />
                    <circle cx="5.73583" cy="5.5566" r="1.5" fill="black" />
                    <circle cx="9.85851" cy="5.5566" r="1.5" fill="black" />
                    <circle cx="9.85851" cy="9.5" r="1.5" fill="black" />
                    <circle cx="13.9811" cy="9.5" r="1.5" fill="black" />
                    <circle cx="5.73583" cy="13.4434" r="1.5" fill="black" />
                    <circle cx="9.85851" cy="13.4434" r="1.5" fill="black" />
                    <circle cx="1.61321" cy="17.3868" r="1.5" fill="black" />
                    <circle cx="5.73583" cy="17.3868" r="1.5" fill="black" />
                  </svg>
                </span>
              </button>
            </BackToAdminWrapper>
          </div>
        )}
        <img
          style={{
            width: "50px",
            height: "50px",
            objectFit: "contain",
            borderRadius: "80px",
            border: "1px dashed #534cab8c",
            boxShadow: "0px 0px 10px #ff98747d",
          }}
          src={fakeImage}
          className="me-3"
          alt
        />
        <div className="d-grid">
          <span
            style={{
              fontWeight: 600,
              color: "#3D434A",
              fontSize: "16px",
            }}
          >
            {name}
          </span>
          <span style={{ color: "#3d434ab3", fontSize: "14px" }}>{email}</span>
        </div>
      </div>
    </Header>
  );
}
