import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ProjectName, secondaryColor, thirdColor } from "./config";
import { persistor, store } from "./redux/store";
import Routers from "./routers";
import GlobalStyles from "./styles/GlobalStyles";
import { useTranslation } from "react-i18next";

function App() {
  document.title = ProjectName;

  const handleLoad = () => {
    // console.log("Page fully l/oaded including stylesheets, images, etc.");
  };

  useEffect(() => {
    window.addEventListener("load", handleLoad);
    return () => {};
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `"Poppins", sans-serif`,
              colorPrimary: thirdColor,
              colorPrimaryHover: secondaryColor,
            },
            components: {
              Input: {
                borderRadius: "18px",
              },
              Button: {
                borderRadius: "18px",
              },
              InputNumber: {
                borderRadius: "18px",
              },
              Dropdown: {
                borderRadius: "18px",
              },
              DatePicker: {
                borderRadius: "18px",
              },
              Upload: {
                borderRadius: "18px",
              },
              Select: {
                borderRadius: "18px",
                colorTextPlaceholder: "#0000008c",
              },
            },
          }}
        >
          <GlobalStyles />
          <Routers />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
